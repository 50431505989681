<template>
  <div>
    <div class="container" v-if="objectInfo">
      <div class="row cleaner-object-info">
        <span>所在位置：{{ mapAddress }}</span>
        <span><a-button @click="setMapAddress">获取位置信息</a-button></span>
      </div>
      <div class="row">
        <span>姓名：</span>
        <span id="name" v-if="source === 'map'" />
        <span v-else>{{ objectInfo.bindName }}</span>
      </div>
      <div class="row">
        <span>身份证号：</span>
        <span id="identityCard" v-if="source === 'map'" />
        <span v-else>{{ objectInfo.bindKey }} </span>
      </div>
      <div class="row">
        <span>设备编号：</span>
        <span id="deviceId" v-if="source === 'map'" />
        <span v-else>{{ objectInfo.deviceId }} </span>
      </div>
      <div class="row">
        <span>SimNo：</span>
        <span id="simNo" v-if="source === 'map'" />
        <span v-else>{{ objectInfo.simNo }} </span>
      </div>
      <div class="row">
        <span>IMEI：</span>
        <span id="imei" v-if="source === 'map'" />
        <span v-else>{{ objectInfo.imei }} </span>
      </div>
      <div class="row">
        <span>速度：</span>
        <span id="speed" v-if="source === 'map'" />
        <span v-else>{{ objectInfo.speed ? Number(objectInfo.speed).toFixed(2) + 'KM/H' : '暂无速度' }} </span>
      </div>
      <div class="row">
        <span>定位时间：</span>
        <span id="deviceTime" v-if="source === 'map'" />
        <span v-else>{{ objectInfo.deviceTime }} </span>
      </div>
      <div class="row">
        <span>设备电量：</span>
        <span id="soc" v-if="source === 'map'" />
        <span v-else>{{ objectInfo.soc ? objectInfo.soc + '%' : '' }} </span>
      </div>
      <div class="row">
        <span>在线状态：</span>
        <span id="online" v-if="source === 'map'" />
        <span v-else>{{ objectInfo.online ? '在线' : '离线' }} </span>
      </div>
    </div>
    <footer-tool ref="footerRef" v-if="showFooter" :objectInfo="footerObj" />
  </div>
</template>
<script>
import FooterTool from '../../../footerTool'
import MonitorBase from '@/views/monitor/monitorBase'
export default {
  name: 'MapCleanerInfoWindow',
  components: { FooterTool },
  mixins: [MonitorBase],
  props: {
    objectInfo: {
      type: Object,
      default: () => {}
    },
    showFooter: {
      type: Boolean,
      default: true
    },
    mapObj: {
      type: Object,
      default: () => {}
    },
    source: {
      type: String,
      default: ''
    }
  },
  watch: {
    objectInfo(newVal, oldVal) {
      if (newVal) {
        this.footerObj.deviceId = newVal.deviceId
        this.footerObj.deviceTime = newVal.deviceTime
      }
    }
  },
  data() {
    return {
      mapAddress: '',
      footerObj: {}
    }
  },
  mounted() {},
  methods: {
    setFormData(form) {
      // console.log('setFormData')
      if (!form) {
        return
      }
      this.lng = form.lng
      this.lat = form.lat
      this.footerObj.deviceId = form.deviceId
      this.footerObj.deviceTime = form.deviceTime
      this.setSpanValue('name', form.bindName)
      this.setSpanValue('identityCard', form.bindKey ? form.bindKey : '')
      this.setSpanValue('deviceId', form.deviceId)
      this.setSpanValue('simNo', form.simNo)
      this.setSpanValue('imei', form.imei ? form.imei : '')
      this.setSpanValue('deviceTime', form.deviceTime)
      // 速度
      const speed = form.speed != null ? Number(form.speed).toFixed(2) : 0
      this.setSpanValue('speed', speed + 'KM/H')
      this.setSpanValue('soc', form.soc ? form.soc + '%' : '')
      this.setSpanValue('online', form.online ? '在线' : '离线')
      this.$refs.footerRef.setData({ deviceId: form.deviceId, deviceTime: form.deviceTime })
    }
  }
}
</script>
<style lang="less" scoped>
.container {
  display: flex;
  flex-direction: column;
  padding-left: 5px;
  padding-right: 5px;
  padding-bottom: 13px;
  .row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0;
    span:first-child {
      min-width: 70px;
    }
  }
}
</style>

<style lang="less">
.cleaner-object-info {
  justify-content: space-between;
  // height: 40px;
  span:first-child {
    padding-right: 3px;
  }
  .ant-btn {
    height: 22px !important;
    border-radius: 1px !important;
    padding-left: 5px;
    padding-right: 5px;
  }
}
</style>
