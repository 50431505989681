var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.objectInfo
        ? _c("div", { staticClass: "container" }, [
            _c("div", { staticClass: "row cleaner-object-info" }, [
              _c("span", [_vm._v("所在位置：" + _vm._s(_vm.mapAddress))]),
              _c(
                "span",
                [
                  _c("a-button", { on: { click: _vm.setMapAddress } }, [
                    _vm._v("获取位置信息"),
                  ]),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "row" }, [
              _c("span", [_vm._v("姓名：")]),
              _vm.source === "map"
                ? _c("span", { attrs: { id: "name" } })
                : _c("span", [_vm._v(_vm._s(_vm.objectInfo.bindName))]),
            ]),
            _c("div", { staticClass: "row" }, [
              _c("span", [_vm._v("身份证号：")]),
              _vm.source === "map"
                ? _c("span", { attrs: { id: "identityCard" } })
                : _c("span", [_vm._v(_vm._s(_vm.objectInfo.bindKey) + " ")]),
            ]),
            _c("div", { staticClass: "row" }, [
              _c("span", [_vm._v("设备编号：")]),
              _vm.source === "map"
                ? _c("span", { attrs: { id: "deviceId" } })
                : _c("span", [_vm._v(_vm._s(_vm.objectInfo.deviceId) + " ")]),
            ]),
            _c("div", { staticClass: "row" }, [
              _c("span", [_vm._v("SimNo：")]),
              _vm.source === "map"
                ? _c("span", { attrs: { id: "simNo" } })
                : _c("span", [_vm._v(_vm._s(_vm.objectInfo.simNo) + " ")]),
            ]),
            _c("div", { staticClass: "row" }, [
              _c("span", [_vm._v("IMEI：")]),
              _vm.source === "map"
                ? _c("span", { attrs: { id: "imei" } })
                : _c("span", [_vm._v(_vm._s(_vm.objectInfo.imei) + " ")]),
            ]),
            _c("div", { staticClass: "row" }, [
              _c("span", [_vm._v("速度：")]),
              _vm.source === "map"
                ? _c("span", { attrs: { id: "speed" } })
                : _c("span", [
                    _vm._v(
                      _vm._s(
                        _vm.objectInfo.speed
                          ? Number(_vm.objectInfo.speed).toFixed(2) + "KM/H"
                          : "暂无速度"
                      ) + " "
                    ),
                  ]),
            ]),
            _c("div", { staticClass: "row" }, [
              _c("span", [_vm._v("定位时间：")]),
              _vm.source === "map"
                ? _c("span", { attrs: { id: "deviceTime" } })
                : _c("span", [_vm._v(_vm._s(_vm.objectInfo.deviceTime) + " ")]),
            ]),
            _c("div", { staticClass: "row" }, [
              _c("span", [_vm._v("设备电量：")]),
              _vm.source === "map"
                ? _c("span", { attrs: { id: "soc" } })
                : _c("span", [
                    _vm._v(
                      _vm._s(
                        _vm.objectInfo.soc ? _vm.objectInfo.soc + "%" : ""
                      ) + " "
                    ),
                  ]),
            ]),
            _c("div", { staticClass: "row" }, [
              _c("span", [_vm._v("在线状态：")]),
              _vm.source === "map"
                ? _c("span", { attrs: { id: "online" } })
                : _c("span", [
                    _vm._v(
                      _vm._s(_vm.objectInfo.online ? "在线" : "离线") + " "
                    ),
                  ]),
            ]),
          ])
        : _vm._e(),
      _vm.showFooter
        ? _c("footer-tool", {
            ref: "footerRef",
            attrs: { objectInfo: _vm.footerObj },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }